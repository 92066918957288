import React from "react";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../config/constant";
import { ReactComponent as IdeaIcon } from "../../assets/icons/ideas-icon.svg";
import { ReactComponent as TechnologyIcon } from "../../assets/icons/technology-icon.svg";
import { ReactComponent as HandShakeIcon } from "../../assets/icons/hand-shake-icon.svg";
import { ReactComponent as AgileIcon } from "../../assets/icons/agile-icon.svg";
import { ReactComponent as TeamIcon } from "../../assets/icons/team-icon.svg";
import ServiceBox from "../OurServices/ServiceBox";
import style from "./style.module.scss";

const OurApproch = () => {
  return (
    <>
      <section id="ourapproch" className={style["values"]}>
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <p>
              Our <span style={{ color: PRIMARY_COLOR }}>Approach</span>
            </p>
            <h2
              className={style["section_sub_title"]}
              style={{
                color: SECONDARY_COLOR,
                marginTop: "15px 150px",
              }}
            >
              We respond to every enterprise software development company
              project with a different perspective, from their point of view to
              be precise. Our structured approach with a digital & technological
              framework highlights the pinpoints to work with for guaranteed
              success.
            </h2>
          </header>
          <div className="row">
            <div
              className="col-md-6 col-lg-4 mb-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <ServiceBox
                color="white"
                icon={<IdeaIcon style={{ fill: "#9BBEF0" }} />}
                title="Ideas"
                subtitle={`We select an idea that transforms businesses based on its nature and working model.`}
              />
            </div>
            <div
              className="col-md-6 col-lg-4 mb-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <ServiceBox
                color="white"
                icon={<TechnologyIcon style={{ fill: "#9BBEF0" }} />}
                title="Technology"
                subtitle={`As per the requirements of clients, we fit them well in its functionality.`}
              />
            </div>
            <div
              className="col-md-6 col-lg-4 mb-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <ServiceBox
                color="white"
                icon={<HandShakeIcon style={{ fill: "#9BBEF0" }} />}
                title="Synergy"
                subtitle={`We always keep transforming enterprise software companies for their business growth.`}
              />
            </div>
            <div
              className="col-md-6 col-lg-4 mb-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <ServiceBox
                color="white"
                icon={<HandShakeIcon style={{ fill: "#9BBEF0" }} />}
                title="Building Trusted Partners"
                subtitle={`We chose to be in very close association with the client to maintain transparency & to ensure better results.`}
              />
            </div>
            <div
              className="col-md-6 col-lg-4 mb-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <ServiceBox
                color="white"
                icon={<AgileIcon style={{ fill: "#9BBEF0" }} />}
                title="Agile Application"
                subtitle={`Agile synonymously runs in our veins, and hence, enterprise software development methodology`}
              />
            </div>
            <div
              className="col-md-6 col-lg-4 mb-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <ServiceBox
                color="white"
                icon={<TeamIcon style={{ fill: "#9BBEF0" }} />}
                title="Empower our Teams"
                subtitle={`Offer creative space for our team so that they can come up with an innovative approach in the project.`}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurApproch;
