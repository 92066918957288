import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/logo.png";
import {
  BsTwitter,
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsArrowUpShort,
} from "react-icons/bs";

import "./footer.scss";
import { NewsLatterAPI } from "../../service/contactus";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (showNotification) {
      setTimeout(() => {
        setShowNotification(false);
      }, 7000);
    }
  }, [showNotification]);

  useEffect(() => {
    if (window !== undefined) {
      let backtotop = document.getElementById("btn-go-to-top");
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add("active");
        } else {
          backtotop.classList.remove("active");
        }
      };
      window.addEventListener("load", toggleBacktotop);
      document.addEventListener("scroll", toggleBacktotop);
    }
  }, []);

  const handleNewsLatter = async () => {
    setLoading(true);
    let resp = await NewsLatterAPI(email);
    setShowNotification(true);
    setEmail("");
    setLoading(false);
  };

  return (
    <>
      <footer id="footer" className="footer">
        <div className="footer-newsletter">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 text-center">
                <h4>Our Newsletter</h4>
              </div>
              <div className="col-lg-6">
                <form action="" method="post">
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                  />
                  {!loading ? (
                    <input
                      type="button"
                      value="Subscribe"
                      onClick={handleNewsLatter}
                    />
                  ) : (
                    <div
                      className="loading"
                      style={{ display: loading ? "flex" : "none" }}
                    >
                      Loading
                    </div>
                  )}
                </form>
                <div
                  className="sent-message"
                  data-aos="flip-up"
                  data-aos-delay="600"
                  style={{
                    display: showNotification ? "block" : "none",
                  }}
                >
                  Your newslatter has been subscribe. Thank you!
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-top">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-5 col-md-12 footer-info">
                <a href="#" className="logo d-flex align-items-center">
                  <img src={Logo} alt="" />
                </a>
                <p><b>CIN </b>: U62099GJ2023PTC142526</p>
                <p><b>GST No </b>: 24AAKCG5627J1ZE</p>
                <p className="mt-2">
                  212, Western Arena, Near Madhuvan Circle, <br />
                  L.P.Savani Road, Adajan,
                  <br />
                  Surat, Gujarat, India - 395009 <br />
                  <br />
                </p>
                {/* <div className="social-links mt-3">
                  <a href="#" className="twitter">
                    <BsTwitter />
                  </a>
                  <a href="#" className="facebook">
                    <BsFacebook />
                  </a>
                  <a href="#" className="instagram">
                    <BsInstagram />
                  </a>
                  <a href="#" className="linkedin">
                    <BsLinkedin />
                  </a>
                </div> */}
              </div>

              <div className="col-lg-2 col-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bi bi-chevron-right"></i> <a href="#">Home</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="#">About us</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="#">Services</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="#">Terms of service</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="#">Privacy policy</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-2 col-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="#">Web Design</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="#">Web Development</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="#">Product Management</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="#">Marketing</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="#">Graphic Design</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                <h4>Contact Us</h4>
                <p>
                  <strong>Phone:</strong> +91 8799600441
                  <br />
                  <strong>Email:</strong> info@growworkinfotech.com
                  <br />
                </p>
                <div className="social-links mt-3">
                  <a
                    href="https://twitter.com/growwork_info"
                    className="twitter"
                  >
                    <BsTwitter />
                  </a>
                  <a
                    href="https://www.facebook.com/growworkinfotech"
                    className="facebook"
                  >
                    <BsFacebook />
                  </a>
                  <a
                    href="https://www.instagram.com/growworkinfotech"
                    className="instagram"
                  >
                    <BsInstagram />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/growwork-infotech"
                    className="linkedin"
                  >
                    <BsLinkedin />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span style={{ color: "#006FE0" }}>Growwork Infotech</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>
      <a
        id="btn-go-to-top"
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i>
          <BsArrowUpShort />
        </i>
      </a>
    </>
  );
};

export default Footer;
