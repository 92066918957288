export const SERVER_URL = "http://13.127.3.188:3000";
// export const SERVER_URL = "http://localhost:3001";

// export const SITE_KEY = "6Lc6cxwlAAAAAAUEKylBaXvgtz4SINH6CuqLAxqG" // domain localhost
export const SITE_KEY = "6Lcz8RwlAAAAAG9gffkZ0et76ntkkhP4GgT2Z6nK"
export const PRIMARY_COLOR = "#006FE0";
export const SECONDARY_COLOR = "#012970";

export const TechnologyList = {
  javascript: [
    "https://images.ctfassets.net/wymh1usfgarm/7rYL42WvdavMQuYVVQj5iy/73f62d261bd470ad5618d57cd11e6ee3/no6273n555-node-js-logo-node-js-logo-nodejs-free-vector-graphic-on-liblogo.png",
    "https://images.ctfassets.net/wymh1usfgarm/35N2rGQ4IdYJwx83Pi704b/fca00c6e90675c5378c5174b1789c7d6/reactJS.png",
    "https://images.ctfassets.net/wymh1usfgarm/5kQLM4DhKD4JgPBi4bDCn6/6e76f68017e916dbd28ec2cc052a94d9/angulerJS.png",
    "https://images.ctfassets.net/wymh1usfgarm/5rxWc45wcFlwoXr7Yawa7C/b22791ca7b7d9568802981d25b1e709d/vueJS.png",
    "https://images.ctfassets.net/wymh1usfgarm/6wXNIJtxx7fbSkHcmnqBe0/1f06ce3c1057af271c4a446b0855f476/nextJS.png",
    "https://images.ctfassets.net/wymh1usfgarm/28EXb1teTQR6JEU1RzHaYi/0be637f96a8d1641156d8754f5a36294/JS.png",
    "https://images.ctfassets.net/wymh1usfgarm/7rYL42WvdavMQuYVVQj5iy/d66545b2746b8605d5077985ea7e62a5/imageedit_5_8129397649.png",
    "https://images.ctfassets.net/wymh1usfgarm/7GQajWLG2RFMX72IkR4Ki2/68b1aa76a281b1cc8eb904c3009532c5/imageedit_1_8738211705.png",
    "https://images.ctfassets.net/wymh1usfgarm/3XPdi7VaDbYFljawkOQlTf/678e53534f1548b9beb85861bd7f6090/imageedit_1_3761459402.png",
    "https://images.ctfassets.net/wymh1usfgarm/TJkYT6wdmf7U6V0zRnZWR/5b4716f0ca624e53f2125273851b3674/jquery.png",
  ],
  php: [
    "https://images.ctfassets.net/wymh1usfgarm/rOqmXOGjAGAloMqXPvcnm/c9fb95bc4db5142ffd8ad7fe03e27a75/php.png",
    "https://images.ctfassets.net/wymh1usfgarm/4CpiPUyx2bJ9kxaTGHKDG3/d6242977a215368491bf4dc2b3e6019d/laravel.png",
    "https://images.ctfassets.net/wymh1usfgarm/1PuWnvWFzPmBIFsiYWIz67/c610f10fba3fc9e75190f59715b4c29c/codeIgniter.png",
    "https://images.ctfassets.net/wymh1usfgarm/3zoLmhSDgbKsAq0SuGjfYw/56b78943c02cb8bf87bbfb0d22eef67c/imageedit_1_8422452370.png",
    "https://images.ctfassets.net/wymh1usfgarm/1uBkUoKfZp84jghEkJLg6l/5536b2fe8437e62b1560940af9923b61/wordpress.png",
  ],
  java: [
    "https://images.ctfassets.net/wymh1usfgarm/7zxjG9ab0SLGGt1HKdLgiP/43f1ed0984e839e8f8a983c40a4607ba/java.png",
    "https://images.ctfassets.net/wymh1usfgarm/7g48dJYXHecm78bTzpwiwZ/96464973a3f4e17a93d183e5643037cf/imageedit_3_3952832586.png",
    "https://images.ctfassets.net/wymh1usfgarm/4LrNP2cqyuQz8isQ0dTCRF/96b7415f25b3c0a4724c715da7684455/hibernate-spring-framework-java-persistence-api-java-annotation-others-d937e9c3d5ca86ef39ffa62790a28ba0.png",
  ],
  mobile: [
    "https://images.ctfassets.net/wymh1usfgarm/5NkMxTSg2XFMLt9Tcdd74D/38fec989451034361d4b113681626db6/ios.png",
    "https://images.ctfassets.net/wymh1usfgarm/1Wa9QsVPeE6ekDm8U2RngQ/8dc560d3961379dfc6e37687471a1b42/android.png",
    "https://images.ctfassets.net/wymh1usfgarm/1QWwtaLx1luNvBkKPmPhNT/1fe12c388ded8820bc38d29446465dab/imageedit_17_8304030340.png",
    "https://images.ctfassets.net/wymh1usfgarm/GmCeb8B26eC188a6HQ69g/b5e510977e502219e9fb2b47bf7ff59b/imageedit_19_4767996748.png",
  ],
  database: [
    "https://images.ctfassets.net/wymh1usfgarm/4djKdIXyPuf3h8bmql3oH0/97bf98db8463681fe4894e4a2cbb6ea7/mongoDB.png",
    "https://images.ctfassets.net/wymh1usfgarm/6hIk02jkYMrXKhknUdSdzj/15683e742638d55a3386463e6c9c4a7d/postgreSQL.png",
    "https://images.ctfassets.net/wymh1usfgarm/44mMYF5TTDjZ9ioNCvaiYQ/f65d64d820114893de1722275a9a64ab/mySQL.png",
    "https://images.ctfassets.net/wymh1usfgarm/2kxDFESkMI7MDs6HVFLH3V/63942808108ae2e835386b2c0f73af5d/kisspng-firebase-database-mobile-backend-as-a-service-push-firebase-5b229ebd0806d1.9532866115289955170329.png",
    "https://images.ctfassets.net/wymh1usfgarm/5owJEOxkPiLcakIzyhf1fe/a2f9e2c9356b76882fa42b8dce642dc0/documentDB.jpg",
  ],
  design: [
    "https://images.ctfassets.net/wymh1usfgarm/5PdHCtFCbU7fPXz1XHIn3Y/c0b1c81c1df9b15ae6ddf7f7c0a54624/photoshop.png",
    "https://images.ctfassets.net/wymh1usfgarm/5MGHkQit8hcdpA7rePYAxG/72775144d6c3f31ec9863961d96f6c8a/adobeXD.png",
    "https://images.ctfassets.net/wymh1usfgarm/6s1UpQCGpCqz7JwhVkOBzu/eef3e616fa41ff7511a700f45df7fe9f/illustrator.png",
    "https://images.ctfassets.net/wymh1usfgarm/274pRYvmc7TA0Sp8eRMLd2/07b1b1948b485ca6012d442a03de35c6/sketch.png",
    "https://images.ctfassets.net/wymh1usfgarm/s2HsdbbCDl9ObXQmhfGjq/ac13b4d99b04d2f04800e6dce32942c8/figma.png",
    "https://images.ctfassets.net/wymh1usfgarm/7CSBX8UYhqt0qswpkDexKz/7ada864f101c72d06a72a8c26db65546/webDesign.png",
    "https://images.ctfassets.net/wymh1usfgarm/1pCSTJrWcX3xHx5ZIzwlws/4a1e938a6373eacdf51363832af2d4f2/bootstrap.svg",
  ],
};
