import React, { useState } from "react";
import Img1 from "../../assets/images/portfolio-1.jpg";
import { PRIMARY_COLOR, TechnologyList } from "../../config/constant";
import Slider from "../Slider/Slider";
import "./style.scss";
import { TechnologyItem } from "./TechnologyItem";

const OurTechnology = () => {
  const [active, setActive] = useState("javascript");
  return (
    <>
      <section id="technology" className="portfolio">
        <div className="container" data-aos="zoom-in">
          <header className="section-header">
            <p>
              Technologies <span style={{ color: PRIMARY_COLOR }}>Stack</span>
            </p>
          </header>

          <div className="row" data-aos="zoom-in" data-aos-delay="100">
            <div className="col-lg-12 d-flex justify-content-center">
              <ul id="portfolio-flters">
                <li
                  className={active === "javascript" ? "filter-active" : ""}
                  onClick={() => setActive("javascript")}
                >
                  JavaScript
                </li>
                <li
                  className={active === "php" ? "filter-active" : ""}
                  onClick={() => setActive("php")}
                >
                  PHP
                </li>
                <li
                  className={active === "java" ? "filter-active" : ""}
                  onClick={() => setActive("java")}
                >
                  JAVA
                </li>
                <li
                  className={active === "mobile" ? "filter-active" : ""}
                  onClick={() => setActive("mobile")}
                >
                  Mobile
                </li>
                <li
                  className={active === "database" ? "filter-active" : ""}
                  onClick={() => setActive("database")}
                >
                  Database
                </li>
                <li
                  className={active === "design" ? "filter-active" : ""}
                  onClick={() => setActive("design")}
                >
                  Design
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="row gy-4 portfolio-container justify-content-center">
            {active === "javascript" &&
              TechnologyList["javascript"].map((item, i) => (
                <TechnologyItem src={item} key={i} />
              ))}
            {active === "php" &&
              TechnologyList["php"].map((item, i) => (
                <TechnologyItem src={item} key={i} />
              ))}
            {active === "java" &&
              TechnologyList["java"].map((item, i) => (
                <TechnologyItem src={item} key={i} />
              ))}
            {active === "mobile" &&
              TechnologyList["mobile"].map((item, i) => (
                <TechnologyItem src={item} key={i} />
              ))}
          </div> */}
          {active === "javascript" && (
            <Slider items={TechnologyList.javascript} />
          )}
          {active === "php" && <Slider items={TechnologyList.php} />}
          {active === "java" && <Slider items={TechnologyList.java} />}
          {active === "mobile" && <Slider items={TechnologyList.mobile} />}
          {active === "database" && <Slider items={TechnologyList.database} />}
          {active === "design" && <Slider items={TechnologyList.design} />}
        </div>
      </section>
    </>
  );
};

export default OurTechnology;
