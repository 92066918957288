import React from "react";
import style from "./landing.module.scss";
import cls from "classnames";
import LandingImg from "../../assets/images/landing-img.png";
import { CgArrowLongRight } from "react-icons/cg";

const LandingMain = () => {
  return (
    <>
      <section id="hero" className="hero d-flex align-items-center">
        <div className={cls("container", style["landing_wrapper"])}>
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h1 data-aos="fade-right" data-aos-delay="400">
                Imagination turns to
                <span style={{ color: "#006FE0" }}>&nbsp;Innovation</span>
              </h1>
              <h2
                className={style.tagline}
                data-aos="fade-right"
                data-aos-delay="400"
              >
                With the help of Innovation and Technology, We give you the
                power to change the world with your ideas and 100% project
                delivery success rate.
              </h2>
              <div data-aos="fade-up" data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    href="#contact"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>
                      Consult Growwork <CgArrowLongRight fontSize={"20px"} />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 hero-img"
              data-aos="fade-down"
              data-aos-delay="200"
            >
              <img src={LandingImg} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LandingMain;
